import React from "react";

import ASASCBriefSummaryV3 from "./ASASCBriefSummaryV3";
import SellingPoints from "./ASASCSellingPoints";

const title = "3rd All Sri Lanka Asteroid Search Campaign";
const description = "Astronomical Research Institute, Sri Lankais functioned under Celestia Group. We are the most qualified and most experienced Astronomical institute in Sri Lanka.<br/> We provide data analysis and research opportunities for our members and network, combining with leading international research institutes. We organize astronomical training programmes, night camps, workshops, stargazing events, public outreach programmes and any technical or enthusiastic sessions related to Astronomy. sponsorship and endorsement of international astronomical organizations. Our team is committed for the continuous improvement of Astronomy and Space science sectors in Sri Lanka by making young astronomers, required for the development of Science and technology fields in the Nation.";

const ASASCAboutV3 = () => (
  <section id="about">
    <ASASCBriefSummaryV3 title= {title} description = "It's time to detect asteroids before they engulf you! Grab the chance of finding asteroids through real
    data analysis and of putting your preferite names on them! Discovering something in outer space is
    an amazing opportunity, but naming them is definitely unique! Astronomical Research Institute, Sri Lanka
    in collaboration with International Astronomical Search Collaboration presents
    the All Sri Lankan Asteroids Searching Campaign."/>
    {/*<SellingPoints />*/}
  </section>
);

export default ASASCAboutV3;
